<!-- eslint-disable vue/no-parsing-error -->
<template>
  <!-- 渠道管理 -->
  <div class="product-audit-box">
    <!-- 搜索框开始 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <template>
        <el-form-item
          label="所属企业"
          prop="cmpCode"
        >
          <el-select
            v-model="formInline.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 搜索框结束 -->
    <!-- 列表开始 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
      >
        <template #expireDate="{ row }">
          <div :class="Date.parse(new Date(row.expireDate)) < Date.parse(new Date()) ? 'expire-date-status' : ''">
            {{ row.expireDate }}
            <span>{{ Date.parse(new Date(row.expireDate)) < Date.parse(new Date()) ? '已到期' : '' }}</span>
          </div>
        </template>
        <template #billOrderStatus="{ row }">
          <span :class="row.billOrderStatus === 3 ? 'blue' : row.billOrderStatus === 4 ? 'grey' : row.billOrderStatus === 6 ? 'green': 'orange'">
            {{ getDictName($store.getters.getDictionaryItem("BILL_ORDER_STATUS"), row.billOrderStatus) }}
          </span>
        </template>
      </Table>
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 列表结束 -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Pagination from '@/components/Pagination2.vue'
import Table from '@/components/Table.vue'
import { channelListPage } from '@/api/piaoju.js'
import { getCmpBaseinfoList } from '@/api/memberManagement'
import { getDate, getDictName } from '@/utils/util.js'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      getDate,
      getDictName,
      cmpNameArr: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'input', label: '渠道名称', value: 'channelName' },
        { type: 'input', label: '渠道联系人', value: 'channelContacts', width: '90' },
        { type: 'select', label: '渠道状态', value: 'status', pLabel: 'label', pValue: 'value', child: [{ label: '已停用', value: 1 }, { label: '启用中', value: 2 }] },
        { type: 'picker', label: '创建日期', value: 'createTimeArr', startTimer: 'startCreateTime', endTimer: 'endCreateTime' }
      ],
      itemData: [
        { label: '所属企业', prop: 'cmpName', width: 200 },
        { label: '渠道编码', prop: 'channelNo', width: 200 },
        { label: '渠道名称', prop: 'channelName', width: 160 },
        { label: '渠道联系方式', prop: 'phoneNo', width: 200 },
        { label: '渠道联系人', prop: 'channelContacts', width: 200 },
        { label: '渠道创建人', prop: 'createUser', width: 200 },
        { label: '创建时间', prop: 'createTime', width: 160 },
        {
          label: '状态',
          prop: 'status',
          width: 160,
          child: [{ label: '已停用', value: 1 }, { label: '启用中', value: 2 }]
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', withParameters: '/piaoju/channelDetails', handleEvent: this.viewDetails }
      ],
      total: 0
    }
  },
  mounted() {
    if (this.$route.query.billOrderStatus) this.formInline.billOrderStatus = this.$route.query.billOrderStatus
    this.remoteMethod('')
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      channelListPage(this.formInline, (res) => {
        if (res.data.records) {
          this.listData = [...res.data.records]
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 详情
    viewDetails({ id }, withParameters) {
      this.$router.push({
        path: withParameters,
        query: { id }
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sub-title {
  position: absolute;
  top: 22px;
  left: 100px;
  color: #666;
}
.view-old-material {
  color: $mainBlue;
  margin-left: 20px;
  cursor: pointer;
}
.expire-date-status {
  color: #D89283;

  span {
    margin-left: 2px;
    display: inline-block;
    background-color: #FCF5F0;
    padding: 2px 4px;
    border-radius: 2px;
  }
}
.blue {
  color: #4595E7;
  background-color: #F4F6FF;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.grey {
  color: #747474;
  background-color: #F2F2F2;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.green {
  color: #09AF42;
  background-color: #F4FCF7;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
.orange {
  color: #ED9948;
  background-color: #FEF7F1;
  padding: 6px 8px;
  text-align: center;
  border-radius: 2px;
}
</style>
